.App {
  direction: rtl;
}
.drawerText,
.drawerIcon {
  color: white !important;
  cursor: pointer;
}
.MuiInputBase-input {
  padding: 7px 5px !important;
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}
